import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form, Badge } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getSections, updateSectionPositionsV3 } from "../../services/sections";
import { getNewsBySectionIdForEdit, getLastActiveNews } from "../../services/news";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../components/layout/Loading";


const ITEM_TYPE = "NEWS_ITEM";


const NewsCard = ({ news, index, type, moveNews, onRemove, onToggleLock }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index, news, type },
    canDrag: () => !news.isLocked, // 🚀 No permitir arrastrar si está bloqueado
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Card ref={!news.isLocked ? drag : null} className={`mt-3 mb-3 ${isDragging ? "opacity-50" : ""}`}>
      <Card.Body className="text-start">
        <h6 style={{ fontSize: "1rem", fontWeight: "bold" }} ><strong>{news.title}</strong></h6>
        <b style={{ fontSize: ".8rem"}} >{news.short_description}</b>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col className="text-start">
            Categoría:{" "}
            <Badge bg="secondary">
              {news.news_category?.name}
              {news.subcategory_name ? `/${news.subcategory_name}` : null}
            </Badge>
          </Col>           
        </Row>
        {type === "board" && (
          <>
            <Row className="mt-2">
              <Col className="text-start">
                ID: {news.id}
              </Col>
            
              <Col className="text-end">
                <Button variant="danger" size="sm" onClick={() => onRemove(index)} disabled={news.isLocked}>
                  <i className="bi bi-trash"></i>
                </Button>
              </Col>
            </Row>
            <Row className="mt-1">
            <Col className="text-start">
                <Form.Check 
                  type="checkbox" 
                  label="Bloquear" 
                  checked={news.isLocked} 
                  onChange={() => onToggleLock(index)}
                />
              </Col>
            </Row>
          </>
        )}
      </Card.Footer>
    </Card>
  );
};
// 📌 Posición en el Tablero (Slot)
const NewsSlot = ({ index, news, moveNews, addNewsToBoard, onRemove, onToggleLock }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: (draggedItem) => {
      if (!news?.isLocked) {
        if (draggedItem.type === "list") {
          addNewsToBoard(draggedItem.news, index);
        } else {
          moveNews(draggedItem.index, index);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`border p-3 text-center h-100 ${isOver ? "bg-warning" : "bg-light"}`}
      style={{ minHeight: "80px" }}
    >
      Posición {index + 1}
      {news ? (
        <NewsCard 
          news={news} 
          index={index} 
          type="board" 
          moveNews={moveNews} 
          onRemove={onRemove} 
          onToggleLock={onToggleLock} 
        />
      ) : null}
    </div>
  );
};

// 📌 Tablero de Noticias
const NewsBoard = ({ newsBoard, setNewsBoard, newsList, setNewsList }) => {
   console.log("newsBoard", newsBoard)
  const moveNews = (fromIndex, toIndex) => {    
    const updatedBoard = [...newsBoard];
    if(updatedBoard[toIndex])
    {
      let fromItem = updatedBoard[fromIndex];
      let toItem = updatedBoard[toIndex];
      updatedBoard[toIndex] = fromItem;
      updatedBoard[fromIndex] = toItem;
    }
    else
    {
      let fromItem = updatedBoard[fromIndex];
      updatedBoard[fromIndex] = null;
      updatedBoard[toIndex] = fromItem;
    }
    setNewsBoard(updatedBoard);
  };


  const addNewsToBoard = (newsItem, targetIndex) => {    
    const updatedBoard = [...newsBoard];
    let newList = [];
    let blockedIndex = updatedBoard
    .map((obj, index) => (obj && obj.isLocked === true ? index : -1)) // Verificamos si obj existe antes de acceder a isLocked
    .filter(index => index !== -1);
    let othersList = updatedBoard.filter(obj => obj && (obj.isLocked === null || obj.isLocked === false));
    for (let i = 0; i <= 19; i++) {
      let isLockedObj = blockedIndex.find((item) => item == i);
      if(targetIndex == i)
      {
         newList.push({...newsItem, isLocked: false });
      }
      else if(isLockedObj)
      {
        newList.push(updatedBoard[isLockedObj]);
      }
      else
      {
         let firstObj = othersList.shift();
         if(firstObj)
         {
          newList.push(firstObj)
         }
      }      
    }
    console.log("newList before", newList.length)
    for (let i = 0; i <= 19; i++) {
      let existObj = newList[i];
      if(!existObj)
      {
          newList.push(null)
      }
    } 
    console.log("newList after", newList.length) 
    setNewsList(newsList.filter((n) => n.id !== newsItem.id));
    setNewsBoard(newList);
  };

  const removeNewsFromBoard = (index) => {
    const updatedBoard = [...newsBoard];
  
    // 🚀 Evitar eliminar noticias bloqueadas
    if (updatedBoard[index]?.isLocked) return;
  
    const removedNews = updatedBoard[index];
    updatedBoard[index] = null;
  
    if (removedNews) {
      setNewsList([...newsList, removedNews]);
    }
  
    setNewsBoard(updatedBoard);
  };

  const toggleLock = (index) => {
    const updatedBoard = [...newsBoard];
  
    if (updatedBoard[index]) {
      updatedBoard[index].isLocked = !updatedBoard[index].isLocked;
      setNewsBoard(updatedBoard);
    }
  };

  return (
    <div>
      <h4 className="mb-3">Posiciones de Noticias</h4>
      <Container>
        <Row>
          {newsBoard.map((news, index) => (
            <Col key={index} md={3} className="mb-3">
              <NewsSlot
                index={index}
                news={news}
                moveNews={moveNews}
                addNewsToBoard={addNewsToBoard}
                onRemove={removeNewsFromBoard}
                onToggleLock={toggleLock}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

// 📌 Lista de Noticias Disponibles
const NewsList = ({ newsList }) => {
  return (
    <div >
      <h4>Noticias Listas {newsList.length}</h4>
      {newsList.map((news, index) => (
        <NewsCard key={news.id} news={news} index={index} type="list" moveNews={() => {}}  />
      ))}
    </div>
  );
};

const ManageSections = () => {
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(0);
    const [newsList, setNewsList] = useState([]);
    const [newsBoard, setNewsBoard] = useState(Array(20).fill(null));
    const [loading, setLoading] = useState(false); // Estado de carga
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
      const fetchSections = async () => {
        try {
          const data = await getSections();
          setSections(data);
          if(data.length == 1)
          {
            loadNewsBySection(data[0].id);
            setSelectedSection(data[0].id);
          }
        } catch {
          toast.error("Error al cargar secciones.");
        }
      };
      fetchSections();
    }, []);
  
    const loadNewsBySection = async (sectionId) => {
      setLoading(true); // Activamos la carga
  
      try {
        const data = await getNewsBySectionIdForEdit(sectionId);
        const updatedBoard = Array(20).fill(null).map((_, index) => data[index] || null);
        setNewsBoard(updatedBoard);
  
        // Obtener todas las noticias activas y excluir las que ya están en el tablero
        const allNews = await getLastActiveNews(70);
        const filteredNews = allNews.filter(n => !updatedBoard.some(slot => slot && slot.id === n.id));
        setNewsList(filteredNews);
      } catch {
        toast.error("Error al cargar noticias.");
      } finally {
        setLoading(false); // Terminamos la carga
      }
    };
  
    const handleSectionChange = (e) => {
      const sectionId = parseInt(e.target.value);
      setSelectedSection(sectionId);
      loadNewsBySection(sectionId);
    };
  
    const saveNewsBoard = async () => {
      if (selectedSection === 0) {
        toast.error("Selecciona una sección antes de guardar.");
        return;
      }  
    
      const positions = newsBoard.map((news, index) => ({
        position: index + 1,
        articleId: news ? news.id : null,
        isLocked: news ? news.isLocked : false,
      }));  
    
      try {
        setIsSaving(true);
        console.log(positions);
        await updateSectionPositionsV3({ positions, sectionId: selectedSection });
        toast.success("Noticias guardadas correctamente.");
        loadNewsBySection(selectedSection);
        setIsSaving(false);
      } catch {
        toast.error("Error al guardar las posiciones.");
        setIsSaving(false);
      }
    };
  
    return (
      <DndProvider backend={HTML5Backend}>
        <Container fluid >
          <Row>
            <Col xl={10}>
              <Form.Group controlId="formRole">
                <Form.Label>Secciones *:</Form.Label>
                <Form.Control as="select" value={selectedSection} onChange={handleSectionChange}>
                  <option key={0} value={0}>Seleccionar sección</option>
                  {sections.map((section) => (
                    <option key={section.id} value={section.id}>{section.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>          
            <Col className="d-flex justify-content-center align-items-center">
              <Button onClick={saveNewsBoard} disabled={selectedSection == 0 ? true: false}  className="mt-3 w-100">
                  { isSaving ? "Guardando": "Guardar" }
              </Button>
            </Col>      
          </Row>
  
          {/* Mostrar `Loading` si está cargando, de lo contrario, mostrar el contenido */}
          {loading ? (
            <Loading />
          ) : (
            <Row className="mt-3">
              <Col md={2} style={{ maxHeight: "2000px", overflowY: "auto" }}>
                <NewsList newsList={newsList} />
              </Col>
              <Col md={10}>
                <NewsBoard
                  newsBoard={newsBoard}
                  setNewsBoard={setNewsBoard}
                  newsList={newsList}
                  setNewsList={setNewsList}
                />
              </Col>
            </Row>
          )}
  
        </Container>
        <ToastContainer />
      </DndProvider>
    );
  };
  

export default ManageSections;