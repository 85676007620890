import { getAiNews, importNews, discardNews, getAiCategories } from '../../services/aiNews';
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, ListGroup, Form, Spinner} from 'react-bootstrap';
import AiNewsCard from '../../components/news/AiNewsCard';
import { getActiveCategories } from '../../services/categories';
import { getActiveSubcategories } from '../../services/subcategories';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../components/layout/Loading';

function ImportNewsFromAI() {
    const [news, setNews] = useState([]);
    const [qty, setQty] = useState(5);
    const [reload, setReload] = useState(true);
    const [selectedNews, setSelectedNews] = useState([]); // Estado para noticias seleccionadas
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [subcategories, setSubcategories] = useState([]);
    const [aiCategories, setAiCategories] = useState([]);    
    const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(0);
    const [selectedAiCategory, setSelectedAiCategory] = useState(0);
    const [excludeImported, setExcludeImported] = useState(true); 
    const [isLoadingAiNews, setIsLoadingAiNews] = useState(false); 
    const [isImporting, setIsImporting] = useState(false); 
    const handleSubmit = () => {
        let ids = []
        for (let item of selectedNews) {
            ids.push(item.id);
        }
        let obj = { ids, category_id: selectedCategoryId, subcategory_id: selectedSubcategoryId  }
        setIsImporting(true);
        importNews(obj).then((result) => {
            if (result.success) {
                toast.success("Se importaron correctamente las noticias")
                setSelectedCategoryId(0);
                setSelectedSubcategoryId(0);
                for (let item of selectedNews) {
                    deselectNews(item.id)
                }
                getAiNews(qty, excludeImported ? 1: 0, selectedAiCategory).then((data) => {
                    setNews(data);
                });
            }
            else {
                toast.error("Algo salio mal")
            }
        }).finally(() => {
            setIsImporting(false);
        })
    }

    const loadMoreNews = () => {
        setQty(qty + 5);
    };
    const handleChange = () => {
        setExcludeImported((prevState) => !prevState); // Cambia el estado
      };
    const discard = (id) => {
        discardNews(id).then(() => {
            setReload(!reload)
        });
    }
    const handleCategoryChange = (e) => {
        const { value } = e.target;
        let id = parseInt(value);
        setSelectedCategoryId(id)
        if (id === 0) {
            setSubcategories([]);
            setSelectedSubcategoryId(0)
        } else {
            getActiveSubcategories(id).then((data) => {
                setSubcategories(data);
            }).finally(() => {
                setSelectedSubcategoryId(0);
            })
        }
    }


    const handleAiCategoryChange = (e) => {
        const { value } = e.target;
        let id = parseInt(value);
        setSelectedAiCategory(id)        
    }

    const handleSubcategoryChange = (e) => {
        const { value } = e.target;
        try {
            let id = parseInt(value);
            setSelectedSubcategoryId(id)
        } catch (error) {
            setSelectedSubcategoryId(0)
        }

    }
    useEffect(() => {
        getAiCategories().then((data) => {
            console.log("getAiCategories",data )
            setAiCategories(data)   
        })
    },[])
    useEffect(() => {
        setIsLoadingAiNews(true);
        getAiNews(qty, excludeImported ? 1: 0, selectedAiCategory).then((data) => {
            setNews(data);            
        }).finally(() => {
            setIsLoadingAiNews(false);
        });
        getActiveCategories().then((data) => {
            setCategories(data);
        })
    }, [qty, excludeImported, reload, selectedAiCategory]);

    // Función para manejar la selección y deselección
    const handleSelectNews = (id, title) => {
        setSelectedNews((prevSelected) => {
            const isSelected = prevSelected.find(news => news.id === id);
            if (isSelected) {
                return prevSelected.filter(news => news.id !== id);
            } else {
                return [...prevSelected, { id, title }];
            }
        });
    };

    // Función para deseleccionar una noticia
    const deselectNews = (id) => {
        setSelectedNews((prevSelected) => prevSelected.filter(news => news.id !== id));
    };

    return (
        <>
        <Container>
            <Row>
                <Col xl={8} className="scrollable">
                    <Row className='mt-2 mb-2'>
                        <Col xl={5} >
                            <Form>
                                <Form.Check 
                                    type="radio" 
                                    id="include-news" 
                                    name="import-toggle" 
                                    label="Incluir Noticias Importadas" 
                                    checked={excludeImported === false} 
                                    onChange={() => handleChange(false)} 
                                />
                                <Form.Check 
                                    type="radio" 
                                    id="exclude-news" 
                                    name="import-toggle" 
                                    label="Excluir Noticias Importadas" 
                                    checked={excludeImported === true} 
                                    onChange={() => handleChange(true)} 
                                />
                            </Form>
                        </Col>
                        <Col xl={7} >
                            <Form.Group>
                                <Form.Label>Filtrar por región:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="ai_category_id"
                                    value={selectedAiCategory}
                                    onChange={handleAiCategoryChange}
                                >
                                    <option key={0} value={0}>Todas las regiones</option>
                                    {
                                    aiCategories  && aiCategories.length > 0 ? 
                                    aiCategories.map((category) => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    )) :
                                    null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {
                            news.length > 0 ?
                            news.map((item, index) => (
                                <Col className='col-xl-6' key={index}>
                                    <AiNewsCard
                                        id={item.id}
                                        title={`${index + 1}. ${item.title}`}
                                        description={item.description}
                                        content={item.content}
                                        categoryName={item.categoryName}
                                        onSelect={handleSelectNews}
                                        selected={!!selectedNews.find(news => news.id === item.id)}
                                        hasBeenImported={item.has_beenImported === null ? false : item.has_beenImported}
                                        date={item.date} 
                                        onDiscard={discard}                          
                                    />
                                </Col>
                            )) :
                            <Row className='mt-4'>
                                <Col className='text-center'>
                                    <h4>No hay noticias nuevas</h4>
                                </Col>
                            </Row>
                        }
                    </Row>
                </Col>
                <Col xl={4}>
                    <Row className='mt-5'>
                        <Col className='text-center'>
                            {/* Botón de importar noticias, deshabilitado según la condición */}
                            <Button 
                                onClick={handleSubmit}
                                disabled={selectedNews.length === 0 || selectedCategoryId === 0}
                            >
                               {
                                 isImporting ? 
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner> :
                                    "Importar noticias seleccionadas"
                               } 
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Seleccionar categoría destino:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="category_id"
                                    value={selectedCategoryId}
                                    onChange={handleCategoryChange}
                                >
                                    <option key={0} value={0}>Seleccionar categoría</option>
                                    {
                                    categories  && categories.length > 0 ? 
                                    categories.map((category) => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    )) :
                                    null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Group controlId="formRole">
                                <Form.Label>Subcategoría *:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subcategory_id"
                                    value={selectedSubcategoryId}
                                    onChange={handleSubcategoryChange}
                                >
                                    <option key={0} value={0}>Seleccionar subcategoría</option>
                                    {subcategories.map((subcategory) => (
                                        <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            {selectedNews.length > 0 ? (
                                <ListGroup>
                                    {selectedNews.map((item) => (
                                        <ListGroup.Item key={item.id}>
                                            {item.title}
                                            <Button variant='link' size="sm" onClick={() => deselectNews(item.id)}>
                                                Eliminar
                                            </Button>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='text-center d-grid gap-2'>
                    <Button  variant='info' onClick={loadMoreNews}>
                      {
                        isLoadingAiNews ?
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </Spinner> :
                        "Cargar más noticias"
                      }    
                    </Button>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
        </>
    );
}

export default ImportNewsFromAI;