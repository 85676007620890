import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ActivateAccount from './pages/auth/ActivateAccount';
import PasswordReset from './pages/auth/PasswordReset';
import PasswordResetRequest from './pages/auth/PasswordResetRequest';
import AddAndEditNews from './pages/news/AddAndEditNews.js';
import Home from './pages/news/Home';
import Manage from './pages/users/Manage';
import EditUser from './pages/users/EditUser';
import ProtectedRoute from './components/layout/ProtectedRoute';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer'
import ManageSections from './pages/news/ManageSections';
import ManageSectionsV2 from './pages/news/ManageSectionsV2';
import ImportNewsFromAI from './pages/news/ImportNewsFromAI';
import ManageMessages from './pages/contactus/page.js';
import UpdateCategoriesName from './pages/categories/ManageCategory.js';
import EditCategory from './pages/categories/EditCategory.js';
import AiNewsLogViewer from './pages/news/AiNewsLogViewer';
import MostRead from './pages/news/MostRead';

import './App.css';
import AddCategory from './pages/categories/AddCategory.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/AddAndEditNews"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <AddAndEditNews />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/MostRead"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <MostRead />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/Home"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container-fluid">
                    <Home />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container-fluid">
                    <Home />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/ManageUsers"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <Manage />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/EditUser"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <EditUser />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />   

        <Route
          path="/ManageSectionsV2"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container-fluid">
                    <ManageSectionsV2 />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />      
        <Route
          path="/ImportNewsFromAI"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <ImportNewsFromAI />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/contactus"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <ManageMessages />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/ManageCategory"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <UpdateCategoriesName />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/EditCategory"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <EditCategory />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/AddCategory"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <AddCategory />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
   <Route
          path="/AiNewsLogViewer"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <AiNewsLogViewer />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />


        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/PasswordReset" element={<PasswordReset />} />
        <Route path="/ActivateAccount" element={<ActivateAccount />} />
        <Route path="/PasswordResetRequest" element={<PasswordResetRequest />} />
        <Route path="/contactus" element={<ManageMessages />} />
        <Route path="/ManageCategory" element={<UpdateCategoriesName />} />
      </Routes>
    </Router>
  );
}

export default App;