import { Container, Row, Col, Button, Form, Alert, Card, ProgressBar } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getActiveCategories } from '../../services/categories';
import { getActiveSubcategories } from '../../services/subcategories';
import { saveDraftNews, getNewsById, updateNews,autoUpdateNews, uploadFiles } from '../../services/news';
import Loading from '../../components/layout/Loading';
import { getStatuses } from '../../services/statuses'
import FilesContainerEdit from '../../components/news/FilesContainerEdit'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getSections } from "../../services/sections";


function AddAndEditNews() {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [sections, setSections] = useState(false);
    const [isUploadingFiles, setIsUploadingFiles] = useState(false);
    const [uploadFilesResult, setUploadFilesResult] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [editorContent, setEditorContent] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [reloadFilesContainer, setReloadFilesContainer] = useState(false);
    const [filesQty, setFilesQty] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isAutoSaving, setIsAutoSaving] = useState(false);
    const [hasSaved, setHasSaved] = useState(false); 
    let id = 0;
    if (localStorage.getItem('news_id') == null || localStorage.getItem('news_id') == undefined) {
        id = 0;
    } else {
        id = parseInt(localStorage.getItem('news_id'));
    }
    const saveDraft = () => {
        setIsLoading(true);
        saveDraftNews(form).then((data) => {
            setForm({
                ...form,
                ["id"]: data.id,
            });
            setIsLoading(false);
        })
    }
    const loadDataToForm = (data) => {
        setForm((prevForm) => {
            if (JSON.stringify(prevForm) === JSON.stringify(data)) {
                return prevForm; // Evita re-render si no hay cambios
            }
            return {
                ...prevForm,
                id: id,
                title: data.title,
                social_title: data.social_title,
                short_description: data.short_description,
                description: data.description,
                descriptionJson: data.descriptionJson,
                descriptionHtml: data.descriptionHtml,
                category_id: data.category_id,
                subcategory_id: data.subcategory_id,
                embedded_code: data.embedded_code,
                status_id: data.status_id,
                scheduled_at: data.scheduled_at_input,
                scheduled_at_section_id: data.scheduled_at_section_id
            };
        });
    
        setTimeout(() => {
            setEditorContent(data.descriptionHtml ? data.descriptionHtml : `<p>${data.description}</p>`);
        }, 500);
    };
    const toolbarOptions = [
        ['link'],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean']
    ];

    const [form, setForm] = useState({
        id: id,
        title: '',
        social_title: '',
        short_description: '',
        description: '',
        descriptionJson: '',
        descriptionHtml: '',
        category_id: 0,
        subcategory_id: 0,
        embedded_code: '',
        status_id: 0,
        scheduled_at: null,
        scheduled_at_section_id:0
    });

    const updateFilesQty = (qty) => {
        setFilesQty(qty);
    };

    const redirectHome = () => {
        navigate('/');
    };

    const handleEditorChange = (content, delta, source, editor) => {
        const plainText = editor.getText();
        setEditorContent(content);
        setForm({
            ...form,
            description: plainText,
            descriptionHtml: content
        });
    };
    const handleDateChange = (e) => {
        const localDate = e.target.value;
        setForm({
            ...form,
            scheduled_at: localDate            
        });     
      };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;        
       
        if(name == "category_id")
        {
            setForm({
                ...form,
                [name]: value,
                ["subcategory_id"]: 0,
            })
        }
        else
        {            
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setIsUploadingFiles(true);
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append(`file${i}`, files[i]);
            }

            uploadFiles(data, form.id)
                .then((result) => {
                    setIsUploadingFiles(false);
                    setUploadFilesResult(result.success);
                    setReloadFilesContainer(!reloadFilesContainer);

                    if (result.success) {
                        toast.success("Los archivos se han subido exitosamente.");
                    } else {
                        toast.error("Hubo un problema al subir los archivos.");
                    }
                })
                .catch(() => {
                    setIsUploadingFiles(false);
                    setUploadFilesResult(false);
                    toast.error("Hubo un problema al subir los archivos.");
                });
        }
    };

    const onUpdateNews = () => {
        updateNews(form).then((result) => {
            if (result.success) {
                toast.success("Se actualizó correctamente");
            } else {
                toast.error("Algo salió mal");
            }
        });
    };
    // const autoSave = () => {
    //     if (isAutoSaving) return; // Evita ejecución duplicada
    
    //     console.log("Ejecutando autoSave"); // 🔍 Depuración
    
    //     setIsAutoSaving(true);
    //     setProgress(100); // Completa la barra antes de guardar
    
    //     setForm((prevForm) => {
    //         console.log("Enviando autoSave con:", prevForm); // Depuración
            
    //         autoUpdateNews(prevForm)
    //             .then(() => {
    //                 setTimeout(() => setProgress(0), 500); // Reinicia la barra tras guardar
    //             })
    //             .catch(() => {
    //                 toast.error("Error en autoguardado ❌");
    //             })
    //             .finally(() => {
    //                 setIsAutoSaving(false);
    //             });
    
    //         return prevForm; // Retorna el mismo estado para no modificarlo en `setForm`
    //     });
    // };
    useEffect(() => {
        if (parseInt(form.category_id) !== 0) {
            getActiveSubcategories(parseInt(form.category_id)).then((data) => {
                setSubcategories(data);
            });
        } else {
            setSubcategories([]);
        }
    }, [form.category_id]);

    useEffect(() => {
        getStatuses().then((data) => setStatuses(data));
        getActiveCategories().then((data) => setCategories(data));
        if (id !== 0) {
            getSections().then((list) => setSections(list));
            getNewsById(id).then((data) => {
                if (data.subcategory_id !== null && data.subcategory_id !== 0) {
                    getActiveSubcategories(parseInt(form.category_id)).then((data) => {
                        setSubcategories(data);
                    }).finally(() => {
                        loadDataToForm(data);
                    });
                } else {
                    loadDataToForm(data);
                }
            });
        }
    }, []); // Se ejecuta una vez al cargar el componente
    // useEffect(() => {
    //     if (id !== 0) {
    //         setHasSaved(false); // Reiniciar la bandera cuando cambia el `id`
    //         const interval = setInterval(() => {
    //             setProgress((oldProgress) => {
    //                 if (oldProgress >= 100) {
    //                     if (!hasSaved) { // Solo ejecutar `autoSave` una vez
    //                         setHasSaved(true); // Marcamos que ya guardamos
    //                         autoSave();
    //                     }
    //                     return 0; // Reinicia el progreso después de guardar
    //                 }
    //                 return oldProgress + (100 / 30); // Incremento gradual
    //             });
    //         }, 1000);
    
    //         return () => {
    //             clearInterval(interval);
    //         };
    //     }
    // }, [id, hasSaved]); 
    return (<>
        <Container className='mb-5'>
            <Row className='justify-content-center mt-3'>
                <Col xl={6}>
                    <Card>
                        <Card.Header>                        
                            {
                                id === 0 ?
                                    <Row >
                                        <Col xl={12}>
                                            Nota 
                                        </Col>
                                    </Row> :                                
                                    <Row >
                                        <Col xl={10}>
                                            Nota
                                        </Col>
                                        <Col xl={2} className='text-end'>
                                            <Form.Label>Id:{id}</Form.Label>
                                        </Col>
                                    </Row>
                                                                     
                            }
                        </Card.Header>
                        <Card.Body>
                            <Row >
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" id='title' name='title' placeholder='Título' value={form.title} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Título Social</Form.Label>
                                        <Form.Control type="text" id='social_title' name='social_title' placeholder='Título Social' value={form.social_title} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Sumario</Form.Label>
                                        <Form.Control type="text" id='short_description' name='short_description' placeholder='Sumario' value={form.short_description} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <label className="font-weight-bold">Nota:</label>
                                    <ReactQuill
                                        value={editorContent}
                                        onChange={handleEditorChange}
                                        modules={{ toolbar: toolbarOptions }}
                                        theme="snow"
                                        placeholder="Escribe la nota aquí..."
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={12}>
                                    <Form.Group>
                                        <Form.Label>Código para embebidos</Form.Label>
                                        <Form.Control
                                            as="textarea" // Esto convierte el control en un <textarea>
                                            id='embedded_code'
                                            name='embedded_code'
                                            placeholder='Código para embebidos'
                                            onChange={handleInputChange}
                                            value={form.embedded_code}
                                            rows={5}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xl={6}>
                                    <Form.Group controlId="formRole">
                                        <Form.Label>Categoría *:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="category_id"
                                            value={form.category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option key={0} value={0}>Seleccionar categoría</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xl={6}>
                                    <Form.Group controlId="formRole">
                                        <Form.Label>Subcategoria *:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="subcategory_id"
                                            value={form.subcategory_id}
                                            onChange={handleInputChange}
                                        >
                                            <option key={0} value={0}>Seleccionar subcategoría</option>
                                            {subcategories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                form.id !== 0 ?
                                <>
                                  
                                    <Row className='mt-3' >
                                        <Col xl={12}>
                                            <Form.Group controlId="formRole">
                                                <Form.Label>Status *:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status_id"
                                                    value={form.status_id}
                                                    onChange={handleInputChange}
                                                >
                                                    {statuses.map((status) => (
                                                        <option key={status.id} value={status.id}>{status.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {
                                        form.status_id == 6 ? 
                                        <>
                                        <Row className='mt-3'>
                                            <Col>
                                            <Form.Group>
                                                <Form.Label>Selecciona la fecha y hora</Form.Label>
                                                <Form.Control
                                                    type="datetime-local"
                                                    value={form.scheduled_at}
                                                    onChange={handleDateChange}
                                                />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="formRole">
                                                    <Form.Label>A que portada va:</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="scheduled_at_section_id"
                                                        value={form.scheduled_at_section_id}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option key={0} value={0}>No mandar a ninguna portada</option>
                                                        {
                                                            sections && sections.length > 0 ?
                                                            sections.map((section) => (
                                                                <option key={section.id} value={section.id}>{section.name}</option>
                                                            )):null
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>                                        
                                        </>
                                        
                                        :
                                        <Row >
                                            <Col>
                                                <b className='text-info'>
                                                <small>
                                                Antes de cambiar el estatus a ‘Online’, asegúrate de que la nota incluya al menos un elemento multimedia como imágenes, videos o códigos embebidos. No es posible actualizar el estatus a ‘Online’ si estos elementos no están presentes.
                                                </small>
                                                </b>
                                            </Col>
                                        </Row>
                                    }
                            
                                    </>
                                     :
                                    null
                            }
                            {
                                isLoading ?
                                    <Loading /> :
                                    form.id == 0 ?
                                        <Row className=' mt-3'>
                                            <Col xl={12} className='text-left'>
                                                <Button className='m-1' variant='secondary' onClick={redirectHome}>Cancelar</Button>
                                                <Button className='m-1' variant='success' onClick={saveDraft} >Guardar</Button>
                                            </Col>
                                        </Row> :
                                        null
                            }
                            {
                                isLoading ?
                                    <Loading /> :
                                    form.id !== 0 ?
                                        <Row className=' mt-3'>
                                            <Col xl={12} className='text-left'>
                                                <Button className='m-1' variant='secondary' onClick={redirectHome} >Cancelar</Button>
                                                <Button className='m-1' variant='success' disabled={isAutoSaving} onClick={onUpdateNews} >
                                                   {
                                                        isAutoSaving ? 
                                                        "Actualizando automáticamente" :
                                                        "Actualizar"
                                                   } 
                                                </Button>
                                            </Col>
                                        </Row> :
                                        null
                            }
                        </Card.Body>
                       
                    </Card>
                </Col>
            </Row>
            {
                form.id == 0 ?
                    null :
                    <>
                        <Row className='justify-content-center mt-3'>
                            <Col xl={6}>
                                <Card>
                                    <Card.Header>
                                        Multimedia
                                    </Card.Header>
                                    <Card.Body>
                                    <Form.Group>
                                        <Form.Label>Selecciona archivos</Form.Label>
                                        <Form.Control
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </Form.Group>
                                    {isUploadingFiles && (
                                        <Alert variant="info" className="mt-3">
                                            Subiendo archivos, por favor espera...
                                        </Alert>
                                    )}
                                    {uploadFilesResult === true && (
                                        <Alert variant="success" className="mt-3">
                                            Los archivos se han subido exitosamente.
                                        </Alert>
                                    )}
                                    {uploadFilesResult === false && (
                                        <Alert variant="danger" className="mt-3">
                                            Hubo un error al subir los archivos.
                                        </Alert>
                                    )}
                                    <FilesContainerEdit
                                        news_id={form.id}
                                        reloadFilesContainer={reloadFilesContainer}
                                        onUpdateFilesQty={updateFilesQty}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
            }
        </Container>
        <ToastContainer position="top-center" />
    </>)
}


export default AddAndEditNews;