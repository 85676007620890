import { Container, Row, Col, Table, Button, Form, Spinner, Alert, Card, FormControl } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import { getProcess_trackers } from '../../services/aiNews';
import LogViewerModal from '../../components/news/LogViewerModal';

function AiNewsLogViewer() {
    const [data, setData] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const openModal = (id) => {
        setShowModal(true)
        setSelectedId(id);
    }
    useEffect(()=> {
        getProcess_trackers(12).then((list) => {
            setData(list);
        })
    },[])
    return (
        <>
        <Container>
            <Row>
                <Col>
                    <Table striped bordered className='mt-2 mb-2'> 
                            <thead>                          
                                <tr>                            
                                    <th className='text-center'>Id</th>
                                    <th className='text-center'>Fecha/Hora</th>   
                                    <th className='text-center'>Tardo en terminar</th>                                        
                                    <th className='text-center'>Contenido</th>                                                                              
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    data && data.length > 0 ? 
                                    data.map((item, index) => 
                                    (
                                        <tr key={index} >                            
                                            <td className='text-center'>{item.id}</td>
                                            <td className='text-center'>{item.created_at}</td> 
                                            <td className='text-center'>{item.minutes ? `${item.minutes} minutos`: item.tag}</td>                                           
                                            <td className='text-center'>
                                                <Button onClick={() => openModal(item.id)}><i class="bi bi-card-list"></i></Button>
                                            </td>                                                                              
                                        </tr>
                                    )) : null
                                }
                            </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <LogViewerModal show={showModal} handleClose={() => setShowModal(false)} id={selectedId} />
        </>
    );
}

export default AiNewsLogViewer;